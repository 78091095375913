// Import and register all your controllers from the importmap under controllers/*

import { application } from "controllers/application"

// Eager load all controllers defined in the import map under controllers/**/*_controller
import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
eagerLoadControllersFrom("controllers", application)
eagerLoadControllersFrom("components", application)

import Sortable from "stimulus-sortable"
application.register("sortable", Sortable)

import ReadMore from 'stimulus-read-more'
application.register("read-more", ReadMore)


import NestedForm from 'stimulus-rails-nested-form'
application.register("nested-form", NestedForm)

import AnimatedNumber from 'stimulus-animated-number'
application.register('animated-number', AnimatedNumber)

import { Autocomplete } from "stimulus-autocomplete"
application.register('autocomplete', Autocomplete)

import TextareaAutogrow from 'stimulus-textarea-autogrow'
application.register('textarea-autogrow', TextareaAutogrow)
